import styles from "./HomeLongBody.module.css";
import { useState } from "react";

export function InHomeLongBody() {
    const [readMore, setReadMore] = useState(false);
    return (
        <section className={`${styles.readMore} bgGray`}>
            <div className="container">
                <div className={`${styles.lowerContnt} ${readMore ? styles.fullHeight : null}`} >
                    <div>
                        <h1>Explore the Best Interior Designers for Your Home at Interior Company</h1>
                        <p>
                            Are you in the midst of a home renovation? Interior Company specialises in expertly guiding and managing every aspect of crafting a home, whether it involves new construction or furnishing. Our dedicated team of interior designers understands every home is unique and seeks each project with fresh eyes, infusing creativity and expertise to breathe life into your vision. The experts at Interior Company offer tailored design solutions for every facet of your home interior design, layout, planning, choice of materials and colours to modular solutions and finishes.
                        </p>
                        <h2>Key Role of Interior Designers in Decorating Your House</h2>
                        <p>
                            Interior designers offer a fresh set of eyes and a wealth of unparalleled ideas. Imagine you are envisioning a space from an expert angle, it may lead to inventive solutions that are not considered otherwise. These experts provide immense expertise and comprehensive solutions that enhance efficiency and maximise utilisation while bringing in a seamless look and well-balanced design. Partner with the Interior Company for a  transformative and tailored design experience. Our remarkable team of interior designers are adept at crafting spaces that not only exude beauty but also align with your lifestyle, personal taste, and budget.
                        </p>
                        <h2>Interior Design Services Offered by Us</h2>
                        <p>
                            Does the idea of transforming your dream home scare you? Are you in a constant dilemma about where to begin? Don&apos;t fret over it! Interior Company is a one-stop solution for all your home decor needs. Our inner house design team follows a well-structured and integrated process, aiming to make your home designing journey enjoyable and stress-free. From concept to execution, our team of experts curate designs that perfectly align your lifestyle, creating a personalised space that truly wows you.
                        </p>
                        <ul>
                            <li>●  Home Interiors (Living room designs, Bedroom spaces, Bathrooms)</li>
                            <li>●  Modular Kitchens</li>
                            <li>●  Modular Wardrobes and Storage Solutions</li>
                            <li>●  Home Decor ( furnishings, art and accessories)</li>
                        </ul>
                        <p>
                            Our in-house designers, decorators, and site managers ensure your home interiors are perfectly executed on-site.
                        </p>
                        <h2>Interior Designers In India</h2>
                        <ul style={{ paddingBottom: '10px' }}>
                            <li>● Top  Interior Designers In Bangalore</li>
                            <li>● Professional Interior Designer In Delhi,</li>
                            <li>● Best Interior Design Consultancy Services In Gurugram,</li>
                            <li>● Residential Interior Designers In Mumbai</li>
                            <li>● Interior Designers and House Decorators In Chennai</li>
                        </ul>
                        <h2>Need of Interior Design Company for Your Modern Home</h2>
                        <p>
                            In today&apos;s fast-paced world, modern homes marry functionality, aesthetics, and personalised style that require a level of expertise and finesse that only interior professionals in the field can provide. Interior Company brings forth a team of experts who are well-versed in the intricacies of modern living, have an innate sense of aesthetics, and access to resources, tools as well as smart technology to curate designs that resonate with the homeowner&apos;s lifestyle.
                        </p>
                        <h2>
                            Hire Residential Interior Designer Professionals for State-of-the-art Work
                        </h2>
                        <p>
                            Renovating your interiors or designing a new home can be overwhelming. Be it finalising concepts, layout, style or choosing materials, colours, and accessories. Here, interior designers play a multi-functional role, from drafting plans to accentuating your living space. Interior Company houses the best home designer in India taking your aspirations, lifestyle choices and preferences into account and crafts harmonious and incredible designs.
                        </p>
                        <b>
                            Start your home journey today, and book a consultation with our experts!
                        </b>
                        <h2>Tailor-made Home Interior Solutions Especially Made for Indian Homes</h2>
                        <p>
                            Designing home interiors, be it for a cosy apartment or an expansive bungalow, can indeed be a task. The crux lies in comprehending your personality and incorporating interior design styles that seamlessly fits within the space and budget. At Interior Company, our remarkable team of interior designers provides professional insights and bespoke solutions that deliver beautiful interiors and awe-inspiring spaces. Whether it’s the living room interiors, bedroom designs, or modular kitchen styles, our team of designers and interior decorators are adept at striking the delicate balance of functionality and esthetics
                        </p>
                        <h2>Reasons Why Interior Company is the Top Interior Design Firm in India</h2>
                        <p>
                            We have a team of seasoned home interior designers with a wealth of experience in the field. Tell them what truly ignites your passion, and we deliver bespoke designs aligned to your needs to suit your taste and budget. We implement the latest trends, cutting-edge tools and techniques, innovative furnishing and methods to breathe life into your spaces that speak your language.
                        </p>
                        <h2>FAQs</h2>
                        <div className={styles.faqsReadmore}>
                            What does an interior designer do?
                        </div>
                        <p>
                            An interior designer at Interior Company coordinates with clients to understand their preferences and draws initial design concepts and space planning proposals while offering a virtual experience to transform their dream home into a reality.
                        </p>
                        <div className={styles.faqsReadmore}>
                            What are the 5 steps to the interior design process?
                        </div>
                        <p>
                            The five phases of the Interior Design process are:
                        </p>
                        <ul>
                            <li>1. Strategic Planning.</li>
                            <li>2. Conceptual Development.</li>
                            <li>3. Design Development.</li>
                            <li>4. Construction Implementation.</li>
                            <li>5. Construction Maintenance & Project Management</li>
                        </ul>

                        <div className={styles.faqsReadmore}>
                            What is a luxury in interior design?
                        </div>
                        <p>
                            Luxury is synonymous with comfort, elegance, and lavishness. It notions individuality and can appear in any design style, from modern to traditional, curating custom spaces and plush furnishings that define a unique visual experience.
                        </p>
                        <div className={styles.faqsReadmore}>
                            How can I get free interior design advice?
                        </div>
                        <p>
                            Click on Interior Company’s website and book a free consultation with our experts. Our team of interior designers take into account your desires and budgetary constraints, planning spaces that resonate with your style.
                        </p>
                        <div className={styles.faqsReadmore}>
                            What is the difference between an interior designer and an interior
                            decorator?
                        </div>
                        <p>
                            An interior designer specialises in comprehensive space planning, architectural concepts, furniture design and material selection, whereas an interior decorator focuses on the style, aesthetics and surface appearance of the living space.
                        </p>
                    </div>
                </div>
                <span className={styles.link} onClick={() => setReadMore(!readMore)}>
                    Read {readMore ? "Less" : "More.."}
                </span>
            </div>
        </section>
    );
}
